import React, { useCallback, useReducer, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '../../1TalkDesign/Buttons';
import Radios from '../../1TalkDesign/Radios';
import BirthSelector from '../../1TalkDesign/BirthSelector';
import {
  studIdNumberIdentify,
  idTypes,
  genderOptions,
} from '../../../utils/user';
import { TextFieldSecondary } from '../../1TalkDesign/Textfield';
import { createMember } from '../../../services/user.service';
import Modal, { ModalHeader, ModalBody, ModalFooter } from 'components/Modal';
import { getURLSearchParamByKey } from 'src/utils';
import { t } from 'src/i18n/config';

const InputField = {
  NAME: 'name',
  ID_NUM: 'idNum',
  RELATION: 'relation',
};

const TextField = styled(TextFieldSecondary)`
  flex: 1;
  margin-top: 16px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

const TopTextField = styled(TextFieldSecondary)`
  flex: 1;
`;

const RadioGroup = styled(Radios)`
  margin-top: 16px;
`;

const Title = styled.div`
  font-size: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.07px;
`;

const CreateButoon = styled(Button)`
  width: 100%;
`;

const Close = styled.i`
  line-height: initial;
  font-size: 1.625rem;
`;

const defaultState = {
  info: {
    name: '',
    birth: '', // format -> '1990/01/01'
    idType: 'twID',
    idNum: '',
    relation: '',
    gender: 'M',
  },
  errInput: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setname':
      return {
        ...state,
        info: {
          ...state.info,
          name: action.payload,
        },
      };
    case 'setidNum':
      return {
        ...state,
        info: {
          ...state.info,
          idNum: (action.payload || '').toUpperCase(),
        },
      };
    case 'setrelation':
      return {
        ...state,
        info: {
          ...state.info,
          relation: action.payload,
        },
      };
    case 'setbirth':
      return {
        ...state,
        info: {
          ...state.info,
          birth: action.payload,
        },
      };
    case 'validate':
      return {
        ...state,
        errInput: action.payload,
      };
    case 'changeType':
      return {
        ...state,
        info: {
          ...state.info,
          idType: action.payload,
        },
      };
    case 'gender':
      return {
        ...state,
        info: {
          ...state.info,
          gender: action.payload,
        },
      };
    default:
      break;
  }
};

const isValid = (errInput) => {
  if (Object.keys(errInput).length !== 0) {
    return false;
  }
  return true;
};

const CreateMember = ({ onClickCancel, onCreate, bindingPhoneNumber }) => {
  const birthSelectorRef = useRef();
  const showPassport = getURLSearchParamByKey('passport') === 'true';
  const generateMemberID =
    getURLSearchParamByKey('generateMemberID') === 'true';
  const [state, dispatch] = useReducer(reducer, defaultState);

  const idOptions = useMemo(() => {
    return showPassport
      ? idTypes
      : idTypes.filter(({ value }) => value !== 'pdID');
  }, [showPassport]);

  const selectedIdType = useMemo(() => {
    return idOptions.find(({ value }) => value === state.info.idType) || {};
  }, [idOptions, state.info.idType]);

  const checkValidation = useCallback(
    (idType) => {
      const errInput = {};
      if (
        !generateMemberID &&
        !studIdNumberIdentify(state.info.idNum, idType)
      ) {
        errInput[InputField.ID_NUM] = `${t('enterCorrectIdType')}${
          selectedIdType.label
        }`;
      }
      if (state.info.name === '') {
        errInput[InputField.NAME] = t('nameFormatError');
      }
      if (state.info.relation === '') {
        errInput[InputField.RELATION] = t('relationshipNameFormatError');
      }
      if (state.info.birth === '' || state.errInput['birth']) {
        const birthError = birthSelectorRef.current.validate();
        errInput['birth'] = birthError;
      }
      dispatch({ type: 'validate', payload: errInput });
      return errInput;
    },
    [
      generateMemberID,
      selectedIdType.label,
      state.errInput,
      state.info.birth,
      state.info.idNum,
      state.info.name,
      state.info.relation,
    ]
  );

  const handleClick = () => {
    const errInput = checkValidation(state.info.idType);
    if (!isValid(errInput)) return;

    if (generateMemberID && !state.info.idNum) {
      state.info.idType = 'memberID';
      state.info.idNum = state.info.name + bindingPhoneNumber;
    }

    createMember(state.info)
      .then((res) => {
        onCreate({
          relation: res.alias,
          ...res,
        });
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const handelDismiss = () => {
    onClickCancel();
  };

  const handleChange = (e) => {
    const changed = e.currentTarget.name;
    dispatch({ type: 'set' + changed, payload: e.currentTarget.value });
  };

  const handleBirthChange = (value) => {
    dispatch({ type: 'setbirth', payload: value });
  };

  const handleIDTypeChange = (idtype) => {
    dispatch({ type: 'changeType', payload: idtype });
  };

  const handleGenderChange = (gender) => {
    dispatch({ type: 'gender', payload: gender });
  };

  return (
    <Modal>
      <ModalHeader>
        <Close onClick={handelDismiss} className="ri-close-line"></Close>
      </ModalHeader>
      <ModalBody>
        <InputContainer>
          <Title>{t('addFamilyMember')}</Title>
          <TextField
            name={InputField.NAME}
            onChange={handleChange}
            placeholder={t('familyMemberName')}
            value={state.info.name}
            helperText={state.errInput[InputField.NAME]}
            error={!!state.errInput[InputField.NAME]}
          />
          <TextField
            name={InputField.RELATION}
            onChange={handleChange}
            placeholder={t('relationshipName')}
            value={state.info.relation}
            helperText={state.errInput[InputField.RELATION]}
            error={!!state.errInput[InputField.RELATION]}
          />
          {!generateMemberID && (
            <>
              <RadioGroup
                options={idOptions}
                defaultValue={state.info.idType}
                onChange={handleIDTypeChange}
              />
              <TopTextField
                name={InputField.ID_NUM}
                onChange={handleChange}
                placeholder={selectedIdType.label}
                value={state.info.idNum}
                helperText={state.errInput[InputField.ID_NUM]}
                inputProps={{ style: { textTransform: 'uppercase' } }}
                error={!!state.errInput[InputField.ID_NUM]}
              />
            </>
          )}
          <BirthSelector
            ref={birthSelectorRef}
            birth={state.info.birth}
            onChange={handleBirthChange}
          ></BirthSelector>
          {(selectedIdType.value === 'pdID' || generateMemberID) && (
            <RadioGroup
              options={genderOptions}
              defaultValue={state.info.gender}
              onChange={handleGenderChange}
            />
          )}
        </InputContainer>
      </ModalBody>
      <ModalFooter>
        <CreateButoon onClick={handleClick}>{t('add')}</CreateButoon>
      </ModalFooter>
    </Modal>
  );
};

CreateMember.propTypes = {
  onClickCancel: PropTypes.func,
};

export default CreateMember;
