import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/layout';
import ListPage from 'components/users/members/ListPage';
import { listMembers } from 'services/user.service';
import { t } from 'src/i18n/config';

const MembersPage = () => {
  const [members, setMembers] = useState(undefined);
  const [bindingPhoneNumber, setBindingPhoneNumber] = useState(undefined);

  useEffect(() => {
    listMembers().then((data) => {
      setMembers(data.members);
      setBindingPhoneNumber(data.bindingPhoneNumber);
    });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>{t('familyMembersSetup')}</title>
      </Helmet>
      <ListPage
        bindingPhoneNumber={bindingPhoneNumber}
        members={members}
        loading={members == null}
      ></ListPage>
    </Layout>
  );
};

export default MembersPage;
