import React, { useCallback, useEffect, useReducer, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import theme from '../../../theme';
import { Button } from '../../1TalkDesign/Buttons';
import CreateMember from './CreateMember';
import { TextFieldSecondary } from '../../1TalkDesign/Textfield';
import { getSearchParamsStrings } from '../../../utils/index';
import { t } from 'src/i18n/config';

const SelectContainer = styled.div`
  margin: 24px 16px 0 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TextField = styled(TextFieldSecondary)`
  margin: 0 8px 0 0;
  flex: auto;
`;

const Pencil = styled.i`
  font-size: 1.625rem;
  color: ${(props) => props.theme.colors.textSecondary};
  line-height: initial;
`;

const CreateMemberButton = styled(Button)`
  align-self: flex-end;
  font-weight: normal;
`;

const MemberSectionContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  align-items: center;
`;

const EmptyMember = styled.div`
  margin-top: 16px;
  width: 100%;
  text-align: center;
`;

function MemberSection({ name, editFunc }) {
  return (
    <MemberSectionContainer>
      <TextField value={name}></TextField>
      <Pencil className="ri-pencil-line" onClick={editFunc}></Pencil>
    </MemberSectionContainer>
  );
}

ListMembers.defaultProps = {
  members: [],
};

const defaultState = {
  onCreate: false,
  onEdit: false,
  member: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'onCreate':
      return {
        ...state,
        onCreate: true,
      };
    case 'onDismisss':
      return defaultState;
    default:
      return defaultState;
  }
};

function ListMembers({ members: originalMembers, bindingPhoneNumber }) {
  const searchParams = getSearchParamsStrings();
  const [state, dispatch] = useReducer(reducer, defaultState);
  const [members, setMembers] = useState(originalMembers);
  useEffect(() => {
    setMembers(originalMembers);
  }, [originalMembers]);

  const _showCreateMemberModalVisible = useCallback(() => {
    dispatch({ type: 'onCreate' });
  }, []);

  const _hideCreateMemberModalVisible = useCallback((res) => {
    if (res != null) {
      setMembers((members) => {
        return [...members, res];
      });
    }
    dispatch({ type: 'onDismiss' });
  }, []);

  if (state.onCreate) {
    return (
      <CreateMember
        onClickCancel={_hideCreateMemberModalVisible}
        onCreate={_hideCreateMemberModalVisible}
        bindingPhoneNumber={bindingPhoneNumber}
      ></CreateMember>
    );
  }

  return (
    <React.Fragment>
      <SelectContainer>
        <CreateMemberButton
          customizedfontcolor={theme.common.primary}
          customizedcolor={'transperant'}
          onClick={_showCreateMemberModalVisible}
        >
          {t('addFamilyMember')}
        </CreateMemberButton>
        {members.length === 0 && (
          <EmptyMember>{t('noMembersAdded')}</EmptyMember>
        )}
        {members.length > 0 &&
          members.map((member) => {
            const { name, id, relation } = member;
            return (
              <MemberSection
                id={id}
                name={`${name} ${relation != null ? `(${relation})` : ''}`}
                editFunc={() =>
                  navigate(`/users/members/edit${searchParams}`, {
                    state: { member, bindingPhoneNumber },
                  })
                }
                key={id}
              ></MemberSection>
            );
          })}
      </SelectContainer>
    </React.Fragment>
  );
}

ListMembers.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
};

export default ListMembers;
